html,
body,
#root,
.app-bar {
  height: 100%;
}

.app-bar {
  display: flex;
  flex-direction: column;
}

.browser-view {
  flex: 1;
}

.arrow-right {
  top: 846px;
  left: 1083px;
  width: 6px;
  height: 12px;
  color: #ffffff;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  opacity: 1;
}

.arrow-left {
  margin-right: 2px;
  margin-left: 6px;
  margin-bottom: 1px;
  background-color: '#fff';
  width: 10px;
  height: 10px;
  border: 1.5px solid #a0a0a0;
  transform: rotate(135deg);
  border-width: 0 2px 2px 0;
  display: inline-block;
  opacity: 1;
}

.arrow-right {
  margin-right: 6px;
  margin-left: 2px;
  margin-bottom: 1px;
  background-color: '#fff';
  width: 10px;
  height: 10px;
  border: 1.5px solid #a0a0a0;
  transform: rotate(315deg);
  border-width: 0 2px 2px 0;
  display: inline-block;
  opacity: 1;
}

.red-dot {
  margin-left: 3px;
  margin-bottom: 10px;
  width: 5px;
  height: 5px;
  border-radius: 2px 2px 2px 2px;
  background: var(--unnamed-color-ff3b30) 0% 0% no-repeat padding-box;
  background: #ff3b30 0% 0% no-repeat padding-box;
  opacity: 1;
}

.red-dot-mobile {
  margin-left: 80%;
  margin-top: -10%;
  width: 5px;
  height: 5px;
  border-radius: 2px 2px 2px 2px;
  background: red;
}

.btn-got-it {
  color: #fff;
  width: 58px;
  height: 30px;
  text-align: center;
  padding: 6px;
  align-self: flex-end;
  margin-right: 20px;
  margin-bottom: 20px;
  background: #65686b 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.btn-done-filter-modal {
  color: #fff;
  width: 58px;
  height: 30px;
  text-align: center;
  padding: 3px;
  margin-left: 100px;
  background: #65686b 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.btn-reset-filter-modal {
  top: 835px;
  left: 698px;
  width: 128px;
  height: 30px;
  text-align: center;
  font: normal normal normal 16px/27px Proxima Nova;
  letter-spacing: 0px;
  color: #65686b;
  opacity: 1;
  border: 1px solid #65686b;
  border-radius: 10px;
  opacity: 1;
}

.contract-modal {
  background-color: #14c232;
  border-radius: 50px;
  opacity: 1;
}

.text-success {
  text-align: left;
  font: normal normal normal 16px/27px 'Proxima Nova';
  letter-spacing: 0px;
  color: #14c232;
  opacity: 1;
}

.label-text {
  width: 100%;
  font-size: 16px;
  color: #65686b;
  font-family: 'Proxima Nova';
}

.calendar-box-filter-completed {
  top: 356px;
  left: 249px;
  width: 219px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #65686b99;
  border-radius: 10px;
  opacity: 1;
}

.calendar-box-filter-mobile {
  top: 356px;
  left: 249px;
  width: 65%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #65686b99;
  border-radius: 10px;
  opacity: 1;
}

.textStyling {
  width: 50%;
  padding-left: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  color: #65686b;
  background: url('http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16.png')
    no-repeat right #eaeaea;
  border-radius: 5px;
  height: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position-x: 95%;
  font-family: 'Proxima Nova';
}

.calendar-box-unselected {
  border: 1px solid #65686b;
  color: #65686b;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.calendar-box-selected {
  border: 1px solid #65686b;
  color: #fff;
  background: #65686b 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.search_categories_mobile {
  width: 82%;
  padding-left: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  color: #65686b;
  border-radius: 5px;
  height: 22px;
  background: url('http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png')
    no-repeat right #eaeaea;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position-x: 95%;
  font-family: 'Proxima Nova';
}

.search_categories {
  width: 112%;
  padding-left: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  color: #65686b;
  border-radius: 5px;
  height: 22px;
  background: url('http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png')
    no-repeat right #eaeaea;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position-x: 95%;
  font-family: 'Proxima Nova';
}

.search_categories .select {
  width: 120%;
  background: url('./images/arrow.png') no-repeat;
  background-position: 80% center;
}

.search_categories .select select {
  background: transparent;
  line-height: 1;
  border: 0;
  padding: 0;
  border-radius: 0;
  width: 120%;
  position: relative;
  z-index: 10;
  font-size: 1em;
}

.search_categories-mobile {
  width: 60%;
  padding-left: 10px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  color: #65686b;
  border-radius: 5px;
  height: 22px;
  background: url('http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png')
    no-repeat right #eaeaea;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position-x: 95%;
  font-family: 'Proxima Nova';
}

.search_categories-mobile .select {
  width: 100%;
  background: url('./images/arrow.png') no-repeat;
  background-position: 80% center;
}

.search_categories-mobile .select select {
  background: transparent;
  line-height: 1;
  border: 0;
  padding: 0;
  border-radius: 0;
  width: 100%;
  position: relative;
  z-index: 10;
  font-size: 1em;
}

.buyer {
  font-family: 'Proxima Nova';
  font-size: '16px';
  letter-spacing: '0px';
  line-height: '1';
  color: '#65686b';
}

.checkbox {
  width: 100%;
  position: relative;
  display: block;
}

.checkbox label {
  position: relative;
  min-height: 30px;
  display: block;
  padding-left: 28px;
  padding-top: 4px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  color: #65686b;
}

.checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 7px;
  width: 16px;
  height: 16px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  border: 2px solid #eaeaea;
  background-color: #eaeaea;
}

.checkbox label:after {
  content: '';
  display: block;
  width: 10px;
  height: 6px;
  border-bottom: 1.5px solid #65686b;
  border-left: 1.5px solid #65686b;
  transform: rotate(-47deg) scale(0);
  transition: transform ease 0.25s;
  position: absolute;
  top: 10px;
  left: 10px;
}

.checkbox input[type='checkbox'] {
  width: auto;
  opacity: 1e-8;
  position: absolute;
  left: 0;
  margin-left: -20px;
}

.checkbox input[type='checkbox']:checked ~ label:before {
  border: 2px solid #eaeaea;
}

.checkbox input[type='checkbox']:checked ~ label:after {
  transform: rotate(-45deg) scale(1);
}

.checkbox input[type='checkbox']:focus + label::before {
  outline: 0;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 15px;
  display: inline-block;
  color: #666;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #65686b;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.suppliers-datatable-container {
  width: 100%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  opacity: 1;
  padding-right: 8px;
  padding-top: 6px;
  margin-bottom: 1px;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  display: none;
}

.p-datatable .p-sortable-disabled .p-sortable-column-badge {
  display: none;
}
