.loaderStyle {
  background: #fff;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1050;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
