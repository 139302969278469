body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Proxima Nova';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ProximaNova-Black';
  src: local('ProximaNova-Black'), url(./fonts/ProximaNova-Black.woff) format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('ProximaNova-Regular'), url(./fonts/ProximaNova-Regular.woff) format('woff');
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: local('ProximaNova-Bold'), url(./fonts/ProximaNova-Bold.woff) format('woff');
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: local('ProximaNova-Semibold'), url(./fonts/ProximaNova-Semibold.woff) format('woff');
}

@font-face {
  font-family: 'ProximaNovaT-Thin';
  src: local('ProximaNovaT-Thin'), url(./fonts/ProximaNovaT-Thin.woff) format('woff');
}
