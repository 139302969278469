.AgNews-img {
  top: 306px;
  left: 45px;
  width: 171px;
  height: 135px;
  /* UI Properties */
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-right: 20px;
}
.Weather-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  /* UI Properties */
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  pointer-events: none;
}
.Weather-text {
  display: block;
  margin: -3px;
  margin-left: auto;
  margin-right: auto;
  /* UI Properties */
  background: transparent 0% 0% no-repeat padding-box;
  max-width: 26;
  max-height: 37;
  text-align: center;
  color: #65686b;
  font-size: 13px;
  opacity: 1;
}
.Weather-date {
  width: 100%;
  font-size: 13px;
  text-align: center;
  color: #65686b;
  font-family: 'Proxima Nova';
  margin: -3px;
  margin-left: 2px;
}
.news-card-outer-div {
  max-width: 171px;
  margin-left: 12px;
}
.news-card-title {
  font-size: 12px;
  color: #a0a0a0;
  margin-bottom: 0px;
  font-family: 'Proxima Nova';
}
.news-card-body {
  font-size: 14px;
  color: #65686b;
  max-height: 235px;
  margin: -5px;
  margin-left: 0px;
  letter-spacing: 0px;
  font-family: 'Proxima Nova';
  line-height: 15px;
}
.news-card-date {
  font-size: 12px;
  color: #a0a0a0;
  margin: -5px;
  margin-left: 0px;
  font-family: 'Proxima Nova';
}
.More-text {
  /* UI Properties */
  color: var(--unnamed-color-65686b);
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 20px/24px Proxima Nova;
  letter-spacing: 0px;
  color: #65686b;
  opacity: 1;
  float: right;
  font-size: 16px;
  margin-right: 20px;
}
.home-root {
  flex-grow: 1;
  margin: 20px;
}
.home-paper {
  padding: 16px;
  text-align: center;
  background-color: #eaeaea;
  border-radius: 10px;
}
.home-paper1 {
  padding: 16px;
  background-color: #eaeaea;
  border-radius: 10px;
}
.home-row1Title {
  font-family: 'ProximaNova-Bold';
  color: #65686b;
  text-decoration: underline;
}
.home-row2Title {
  font-family: 'ProximaNova-Bold';
  color: #65686b;
  margin-bottom: 10;
}
.upcoming-date-mobile {
  font-size: 14px;
  text-align: center;
  margin-left: 10px;
  font-family: 'Proxima Nova';
  color: #65686b;
}
.upcoming-date-browser {
  display: block;
  margin: -3px;
  margin-left: auto;
  margin-right: auto;
  /* UI Properties */
  background: transparent 0% 0% no-repeat padding-box;
  max-width: 26;
  max-height: 37;
  text-align: center;
  color: #65686b;
  font-size: 13px;
  opacity: 1;
 
}
.upcoming-tomorrow-text {
  display: block;
  margin: -3px;
  margin-left: auto;
  margin-right: auto;
  /* UI Properties */
  background: transparent 0% 0% no-repeat padding-box;
  max-width: 26;
  max-height: 37;
  text-align: center;
  color: #a0a0a0;
  font-size: 13px;
  opacity: 1;
}
.upcoming-tomorrow-text-hidden {
  display: none;
}
.upcoming-update-text {
  font-size: 13px;
  color: #65686b;
  text-align: left;
  margin-top: 0px;
  font-family: 'Proxima Nova';
}
.more-arrow {
  position: absolute;
  top: 25%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  width: 8px;
  height: 8px;
  border: 1px solid #65686b;
  transform: rotate(-45deg);
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  opacity: 1;
  margin-left: 10px;
}
