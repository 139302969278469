.CBIS-title {
  text-align: right;
  font: normal normal bold 36px/34px ProximaNova-Semibold;
  letter-spacing: 0px;
  color: #65686b;
  opacity: 1;
  text-align: center;
  font-size: 30px;
  margin-top: 10px;
}
.CBIS-title-mobile {
  width: 100%;
  text-align: center;
  font: normal normal bold 36px/34px ProximaNova-Bold;
  letter-spacing: 0px;
  color: #002554;
  opacity: 1;
  font-size: 30px;
}
.CBIS-logo {
  margin-left: 10px;
}
.logo-mobile {
  margin-top: -15px;
}
.icon-weather-toolbar {
  margin-left: 10px;
  margin-top: 8px;
  width: 20px;
  height: 15px;
}
.toolbar-desktop {
  background-color: 'white';
  width: 100%;
}
.toolbar-mobile {
  background-color: 'white';
  width: 100%;
  margin-top: 10px;
}
.search-in-drawer {
  background: var(--unnamed-color-eaeaea) 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--unnamed-color-a0a0a0);
  background: #eaeaea 0% 0% no-repeat padding-box;
  border: 0.5px solid #a0a0a0;
  border-radius: 5px;
  opacity: 1;
}
.setting-back-arrow {
  position: absolute;
  top: 25%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  width: 11px;
  height: 11px;
  border: 1px solid #65686b;
  transform: rotate(-45deg);
  border-width: 2px 0 0 2px;
  display: inline-block;
  opacity: 1;
  margin-top: 37px;
  margin-left: 5px;
}
