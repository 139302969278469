.search-turnin-number-container {
  display: flex;
  justify-content: flex-start;
  background: #eaeaea 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--unnamed-color-a0a0a0);
  border-radius: 5px;
}

.search-turnin-number-container:focus-within {
  outline: -webkit-focus-ring-color auto 1px;
}

.search-turnin-number {
  width: 100%;
  height: 33px;
  background: #eaeaea 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--unnamed-color-a0a0a0);
  border-radius: 5px;
  opacity: 1;
  font-family: 'Proxima Nova';
  outline: none;
}

.MuiTableCell-root {
  text-align: center;
}
