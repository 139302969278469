.Rectangle215 {
  border: 2px solid #eaeaea;
  border-radius: 10px;
  opacity: 1;
}
.Contracts-right-title-mobile {
  width: 100%;
  text-align: center;
  font: normal normal 16px/19px;
  letter-spacing: 0px;
  color: #002554;
  opacity: 1;
  font-family: 'ProximaNova-Bold';
  font-size: 16px;
  margin: 10px;
  float: flex-end;
}
.divr {
  display: flex;
  align-items: center;
}
.btn-back {
  top: 835px;
  left: 751px;
  width: 61px;
  height: 30px;
  border: 1px solid #65686b;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 16px/27px Proxima Nova;
  letter-spacing: 0px;
  color: #65686b;
}
.btn-save {
  top: 835px;
  left: 872px;
  width: 50px;
  height: 30px;
  border: 1px solid #65686b;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 16px/27px Proxima Nova;
  letter-spacing: 0px;
  color: #65686b;
}
.btn-submit {
  top: 835px;
  left: 981px;
  width: 68px;
  height: 30px;
  padding-top: 5px;
  text-align: center;
  font: normal normal normal 16px Proxima Nova;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #65686b 0% 0% no-repeat padding-box;
  background-color: #65686b;
  border-radius: 10px;
}
.btn-confrm-next {
  top: 835px;
  left: 944px;
  width: 157px;
  height: 30px;
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 5px;
  text-align: left;
  font: normal normal normal 16px Proxima Nova;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #65686b 0% 0% no-repeat padding-box;
  background-color: #65686b;
  border-radius: 10px;
  opacity: 1;
}
.btn-signature-pdf {
  margin: 20px;
  height: 28px;
  background: #65686b 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  color: var(--unnamed-color-ffffff);
  font: normal normal normal 16px/19px Proxima Nova;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  overflow: hidden;
}
.contract-section {
  margin-top: 6px;
}
.title-bold-sm {
  font: normal normal 16px 'ProximaNova-Bold';
  letter-spacing: 0px;
  color: #65686b;
  font-family: 'ProximaNova-Bold';
  font-size: small;
}
.text-regular-sm {
  font: normal normal 16px 'Proxima Nova';
  font-size: 16px;
  letter-spacing: 0px;
  color: #65686b;
}
.label-completed-results {
  top: 282px;
  left: 20px;
  width: 103px;
  height: 16px;
  text-align: left;
  font: normal normal normal 14px/17px Proxima Nova;
  letter-spacing: 0px;
  color: #a0a0a0;
  opacity: 1;
  font-family: 'Proxima Nova';
}
.editField-temp {
  font: normal normal 16px 'Proxima Nova';
  letter-spacing: 0px;
  background: var(--unnamed-color-eaeaea) 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--unnamed-color-a0a0a0);
  background: #eaeaea 0% 0% no-repeat padding-box;
  padding-left: 10px;
  border-radius: 5px;
  opacity: 1;
}
.contract-field-textarea-sm {
  width: 100%;
  font: normal normal 12px 'Proxima Nova';
  letter-spacing: 0px;
  background: var(--unnamed-color-eaeaea) 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--unnamed-color-a0a0a0);
  background: #eaeaea 0% 0% no-repeat padding-box;
  padding-left: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 5px;
  margin-top: 2px;
  opacity: 1;
  font-size: 12px;
  color: #727171;
  word-wrap: break-word;
}
.search-contract-number {
  width: 100%;
  height: 22px;
  color: #a0a0a0;
  background: #eaeaea 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--unnamed-color-a0a0a0);
  border-radius: 5px;
  opacity: 1;
  font-family: 'Proxima Nova';
}
.search-contract-number-container {
  display: flex;
  background: #eaeaea 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--unnamed-color-a0a0a0);
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 2px;
}
.search-supplier-filter-modal {
  width: 100%;
  height: 22px;
  color: #a0a0a0;
  background: #eaeaea 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--unnamed-color-a0a0a0);
  border-radius: 5px;
  opacity: 1;
  margin-bottom: 3px;
  font-family: 'Proxima Nova';
}
.row-margin {
  margin-top: 2px;
}
.input-native-contract-specs {
  width: 72px;
  height: 22px;
  background: #eaeaea 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: 0px solid var(--unnamed-color-a0a0a0);
}
.datatable-container {
  top: 320px;
  height: 40px;
  margin-bottom: 2px;
  border: 1px solid #eaeaea;
  border-radius: 10px 0px 0px 10px;
  opacity: 1;
}
.datatable-container.row {
  margin: 0;
  margin-top: 3px;
}
.datatable-title {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: left;
  font: normal normal bold 16px;
  letter-spacing: 0px;
  color: #65686b;
  opacity: 1;
  font-size: 16px;
}
.datatable-description {
  text-align: left;
  font: normal normal normal 14px;
  letter-spacing: 0px;
  color: #65686b;
  opacity: 1;
  font-size: 14px;
  margin-bottom: 2px;
  padding-bottom: 2px;
  white-space: pre-line;
  line-height: 18px;
}
.datatable-arrow {
  position: absolute;
  background-color: '#000';
  top: 25%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  width: 13px;
  height: 13px;
  border: 2px solid #a0a0a0;
  transform: rotate(-45deg);
  border-width: 0 2px 2px 0;
  display: inline-block;
  opacity: 1;
}
.arrow-confirm-next {
  margin-left: 2px;
  background-color: '#fff';
  width: 10px;
  height: 10px;
  border: 1.5px solid #fff;
  transform: rotate(-45deg);
  border-width: 0 2px 2px 0;
  display: inline-block;
  opacity: 1;
}
.arrow-back {
  margin-right: 2px;
  margin-left: 3px;
  background-color: '#fff';
  width: 10px;
  height: 10px;
  border: 1.5px solid #65686b;
  transform: rotate(135deg);
  border-width: 0 2px 2px 0;
  display: inline-block;
  opacity: 1;
}
.red-dot {
  margin-left: 3px;
  margin-bottom: 10px;
  width: 5px;
  height: 5px;
  border-radius: 2px 2px 2px 2px;
  background: var(--unnamed-color-ff3b30) 0% 0% no-repeat padding-box;
  background: #ff3b30 0% 0% no-repeat padding-box;
  opacity: 1;
}
.btn-got-it {
  color: #fff;
  width: 58px;
  height: 30px;
  text-align: center;
  font-size: 16px;
  font-family: 'Proxima Nova';
  padding: 3px;
  align-self: flex-end;
  margin-right: 20px;
  margin-bottom: 20px;
  background: #65686b 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.btn-done-filter-modal {
  color: #fff;
  width: 58px;
  height: 30px;
  text-align: center;
  padding: 3px;
  margin-left: 100px;
  background: #65686b 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.btn-reset-filter-modal {
  top: 835px;
  left: 698px;
  width: 128px;
  height: 30px;
  text-align: center;
  font: normal normal normal 16px/27px Proxima Nova;
  letter-spacing: 0px;
  color: #65686b;
  opacity: 1;
  border: 1px solid #65686b;
  border-radius: 10px;
  opacity: 1;
}
.text-success {
  text-align: left;
  font: normal normal normal 16px/27px;
  letter-spacing: 0px;
  color: #14c232;
  opacity: 1;
  font-family: 'Proxima Nova';
  margin: 10;
}
.label-text {
  width: 100%;
  font-size: 16px;
  color: #65686b;
  font-family: 'Proxima Nova';
}
.label-text-supplier {
  width: 100%;
  font-size: 15px;
  color: #65686b;
  font-family: 'Proxima Nova';
  margin-left: 10px;
}
.calendar-box-filter-completed {
  top: 356px;
  left: 249px;
  width: 219px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #65686b99;
  border-radius: 10px;
  opacity: 1;
}
.sub-category-sm {
  text-align: 'end';
  font-family: 'Proxima Nova';
  color: #65686b;
  font-size: small;
}
