.btn-search {
  top: 835px;
  left: 944px;
  padding: 8px;
  text-align: left;
  font: normal normal normal 16px Proxima Nova;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #65686b 0% 0% no-repeat padding-box;
  background-color: #65686b;
  border-radius: 10px;
  opacity: 1;
}
.btn-clear {
  top: 835px;
  left: 944px;
  padding: 8px;
  text-align: left;
  font: normal normal normal 16px Proxima Nova;
  letter-spacing: 0px;
  color: #65686b;
  border: 1px solid #65686b;
  opacity: 1;
  border-radius: 10px;
  opacity: 1;
  margin-right: 20;
}
.btn-backto {
  left: 944px;
  text-align: left;
  font-size: 16px;
  padding: 4px;
  font-family: 'Proxima Nova';
  letter-spacing: 0px;
  color: #65686b;
  border: 1px solid #65686b;
  opacity: 1;
  border-radius: 10px;
  padding-right: 8px;
}
.title {
  font: normal normal 16px 'ProximaNova-Bold';
  letter-spacing: 0px;
  color: #65686b;
  font-family: 'ProximaNova-Bold';
  margin-left: 10%;
  padding-left: 25;
}
.heading {
  color: #002554;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}
.title-nonbold {
  font: normal normal normal 16px Proxima Nova;
  font-size: 16;
  color: #65686b;
}
.buyer-supplier {
  font: normal normal 16px 'ProximaNova-Bold';
  letter-spacing: 0px;
  color: #65686b;
  font-family: 'ProximaNova-Bold';
  text-align: center;
  margin-top: -37px;
}
.calendarStyling {
  display: flex;
  margin-top: 10%;
  justify-content: space-between;
}
.calendarDisplay {
  display: flex;
  margin-top: 10%;
  justify-content: space-between;
}
.selectStying {
  justify-content: center;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: inherit;
}

@media (max-width: 960px) and (min-width: 400px) {
  .grid {
    margin-left: 50%;
  }
  body {
    width: 100%;
    overflow-x: hidden;
  }
}

.harvested {
  /* position: fixed; */
  width: -webkit-fill-available;
  width: -moz-available;
}
.search_categories_suppliers {
  width: 90%;
  padding-left: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  color: #65686b;
  border-radius: 5px;
  height: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position-x: 95%;
  font-family: 'Proxima Nova';
  background-color: #eaeaea;
}

.datatable-style .row-accessories > td:not(:last-child){
  font-weight: bold;
  background-color: #002554 !important;
  color:  white !important;
}


.datatable-style .row-accessories-detail > td{
  font-weight: bold;
  background-color: #002554 !important;
  color:  white !important;
}
